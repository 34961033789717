.eventos-mfe-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1452px) {
  .eventos-mfe-wrapper {
    max-width: 1500px !important;
  }
}

/*Deshabilita el show password nativo de MS Edge*/
::-ms-reveal{
  display: none;
}

:root {
  --color-principal: rgb(63, 155, 217);
  --tr-par: #fff;
  --tr-impar: #eee;
  --tr-hover: #cdeefd;
  --fondo-app: #e9e9e9;
  --bionline: rgb(63, 155, 217);
  --bionline-dark: rgba(68, 114, 196, 1);
  --bionline-hover: #295396;
  --fondo-layout: #fff;
  --color-rotulos-mkt: #555;
  --color-letra-headings: #fff;
  --color-letra-layout: #000;
  --color-letra-light: #919394;
  --fondo-layout-hover: var(--bionline);
  --color-letra-layout-hover: #fff;
  --boton-alternativo: #69b9cc;
  --mensaje-correcto: rgb(57, 189, 57);
  --mensaje-error: rgb(187, 32, 32);

  --error: rgba(202, 11, 4, 1);
  --error-dark: rgba(157, 7, 7, 1);

  --success: rgba(44, 183, 75, 1);
  --success-dark: rgba(18, 114, 39, 1);
  --warning: #ffb763;
  --warning-dark: rgba(245, 107, 29, 1);

  --error-shadow: 0 0 6px -1px rgb(255 0 0 / 28%);
  --degradado: linear-gradient(
    90deg,
    rgba(68, 114, 196, 1) 0%,
    rgba(32, 198, 243, 1) 100%
  );
  --bis-blue: #267bd1;
  --bis-purple: #7b26da;
  --bis-gray: #b7b7b7;
  --bis-pink: rgba(163, 160, 251, 0.05);

  --bis-tooltip-black: rgba(0, 0, 0, 0.6);
  --bis-tooltip-white: rgba(255, 255, 255, 0.75);
  --bis-tooltip-blue: #11c7fe;

  --bis-primary-button: #3f9bd9;
  --bis-primary-button-dark: #267bd1;

  --bis-error: rgb(187, 32, 32);

  --degradado-warning: linear-gradient(
    90deg,
    rgba(245, 107, 29, 1) 0%,
    rgba(255, 183, 99, 1) 100%
  );
  --degradado-error: linear-gradient(
    90deg,
    rgba(157, 7, 7, 1) 0%,
    rgba(255, 15, 0, 1) 100%
  );
  --degradado-success: linear-gradient(
    90deg,
    rgba(18, 114, 39, 1) 0%,
    rgba(44, 183, 75, 1) 100%
  );
  --fondo-degradado: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) -25.19%,
      rgba(162, 229, 249, 0.0502404) 18.24%,
      rgba(0, 169, 222, 0.12) 74.96%,
      rgba(69, 118, 198, 0.12) 100%,
      rgba(0, 91, 192, 0.12) 100%
    ),
    #ffffff;
}

@font-face {
  font-family: "Montserrat";
  src: "./fonts/Montserrat-Regular.woff2" format("woff2"),
    "./fonts/Montserrat-Regular.woff" format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: "./fonts/Montserrat-Regular.woff2" format("woff2"),
    "./fonts/Montserrat-Regular.woff" format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: "./fonts/Montserrat-Regular.woff2" format("woff2"),
    "./fonts/Montserrat-Regular.woff" format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

::-webkit-input-placeholder {
  /* Edge */
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
}

::placeholder {
  font-size: 14px;
}

#root {
  background: linear-gradient(to bottom right, #fdfeff, #d1d8e5);
}

body {
  position: relative;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
.container {
  padding-top: 30px;
  padding-bottom: 30px;
}

body {
  height: 100vh;
}

.sinScroll,
.sinScroll1 {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgb(240, 240, 240);
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(187, 187, 187);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(136, 136, 136);
}

.tooltip-top{
  font-size:13px;
}
